import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS

export default class SolicitudesService {
  search (params) {
    return http.get(`${baseUrl}/solicitudes/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/solicitudes/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  save (payload) {
    return http.post(`${baseUrl}/solicitudes`, payload, {
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/solicitudes/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  edit (payload, id) {
    return http.put(`${baseUrl}/solicitudes/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  remove (id) {
    return http.delete(`${baseUrl}/solicitudes/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  reply (payload) {
    const { id } = payload
    delete payload.id
    return http.put(`${baseUrl}/solicitudes/respuesta/${id}`, {
      ...payload
    })
  }

  removePdf (id) {
    return http.delete(`${baseUrl}/pdf/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
