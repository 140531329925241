<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="solicitudes.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             v-model:filters="filters1"
             filterDisplay="menu"
             responsiveLayout="scroll"
             removableSort
  >
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" header="Id">
      <template #body="{data}">
        {{ data.id }}
      </template>
    </Column>

    <Column field="tipo" header="Tipo" style="min-width:10rem" :showFilterMatchModes="false">
      <template #body="{data}">
        <span class="font-bold text-blue-800" @click="ver(data)">{{ $h.capitalize(data.tipoName) }}</span>
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <Dropdown v-model="filterModel.value" :options="tipoSolicitudes" optionLabel="nombre" optionValue="id" placeholder="Seleccione" :filter="true" />
      </template>
    </Column>

    <Column field="solicitud" header="Solicitud" style="min-width:10rem">
      <template #body="{data}">
        {{ data.solicitud }}
      </template>
    </Column>

    <Column field="solicitudId" header="Solicitado A">
      <template #body="{data}">
        {{ data.solicitado.fullName }}
      </template>
    </Column>

    <Column field="createdAt" header="Fecha Solicitud" style="min-width:10rem">
      <template #body="{data}">
        {{ $h.formatDate(data.createdAt, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
    </Column>

    <Column field="createdBy" header="Creado Por" style="min-width:10rem" :showFilterMatchModes="false">
      <template #body="{data}">
        {{ data.solicitante.fullName }}
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <Dropdown v-model="filterModel.value" :options="usuarios" optionLabel="fullName" optionValue="id" placeholder="Cualquiera" :filter="true" />
      </template>
    </Column>

    <Column field="respuesta" header="Respuesta">
      <template #body="{data}">
        <template v-if="data.respuesta">
          {{ data.respuesta }}
        </template>
        <template v-else>
          No tiene
        </template>
      </template>
    </Column>

    <Column header="Acciones" style="width: 10rem">
      <template #body="{data}">
        <div class="flex gap-4 justify-center">
          <template v-if="!data.respuesta">
            <Button icon="pi pi-reply" class="p-button-rounded p-button-help" @click="$router.push({ name: 'pharmasan.administrativa.juridica.solicitudes.responder', params: { id: data.id } })" v-tooltip.top="{value: 'Responder'}" />
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="$router.push({ name: 'pharmasan.administrativa.juridica.solicitudes.crear', params: { id: data.id } })" />
            <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" /> -->
          </template>
          <div v-else>
            <Button @click="$router.push({ name: 'pharmasan.administrativa.juridica.solicitudes.', params: { id: data.id } })" label="Respuesta" icon="pi pi-eye" class="p-button-rounded p-button-primary" />
          </div>
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.offset"
    :rows="paginator.limit"
    :totalRecords="solicitudes.count"
    :rowsPerPageOptions="[2,10,20,30]"
    @page="onPage($event)"
  />
</div>
</template>

<script>
import solicitudesStore from '../../../../../../../store/solicitudes.store'
import { computed, onMounted, ref, watch } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import UserSerivce from '@/apps/pharmasan/juridica/procesosJuridicos/services/usuarios.service'

export default {
  name: 'TablaSolicitudesDerecho',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const _PdfService = new PdfService()
    const _UserService = new UserSerivce()
    const solicitudes = computed(() => solicitudesStore.getters.solicitudes)
    const tipoSolicitudes = computed(() => solicitudesStore.getters.solicitudTipo)
    const usuarios = ref([])
    const paginator = ref({
      offset: 0,
      page: 0,
      limit: 10
    })
    const filters1 = ref({
      tipo: {
        value: 5,
        matchMode: FilterMatchMode.EQUALS
      },
      base: {
        value: route.params.id,
        matchMode: FilterMatchMode.EQUALS
      }
    })
    const clearFilter1 = () => {
      filters1.value = {
        tipo: {
          value: 1,
          matchMode: FilterMatchMode.EQUALS
        },
        base: {
          value: route.params.id,
          matchMode: FilterMatchMode.EQUALS
        }
      }
    }

    const ver = ({ tipo, base }) => {
      if (tipo === 1) {
        router.push({ name: 'pharmasan.administrativa.juridica.tutelas.ver', params: { id: base } })
      } else if (tipo === 2) {
        router.push({ name: 'procesos.judiciales.incidencias.ver', params: { id: base } })
      } else if (tipo === 3) {
        router.push({ name: 'procesos.judiciales.fallos.ver', params: { id: base } })
      } else if (tipo === 4) {
        router.push({ name: 'procesos.judiciales.respuestas.ver', params: { id: base } })
      } else {
        Swal.fire('Error', 'No existe este tipo', 'error')
      }
    }

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const onPage = ({ first, page, pageCount, rows }) => {
      paginator.value.offset = first
      paginator.value.page = page
      solicitudesStore.dispatch('getPaginate', {
        paginator: paginator.value,
        filters: filters1.value
      })
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina esta solicitud no se podrá recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          solicitudesStore.dispatch('removeSolicitud', id).then(async () => {
            await solicitudesStore.dispatch('getPaginate', {
              paginator: paginator.value,
              filters: filters1.value
            })
            Swal.fire(
              'Eliminado!',
              'El juzgado fue eliminado con exito.',
              'success'
            )
          })
        }
      })
    }

    const reply = ({ id }) => {
      Swal.fire({
        title: 'Digite la respuesta',
        icon: 'info',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Responder',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          console.log(login)
          try {
            if (!login) throw new Error('El campo no puede estar vacio')
            return solicitudesStore.dispatch('reply', { id, respuesta: login })
          } catch (error) {
            Swal.showValidationMessage(
              `${error}`
            )
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          solicitudesStore.dispatch('getPaginate', {
            paginator: paginator.value,
            filters: filters1.value
          })
          Swal.fire({
            title: 'Respuesta enviada',
            text: 'La respuesta fue enviada con exito',
            icon: 'success'
          })
        }
      })
    }

    watch(filters1, (a, b) => {
      paginator.value.offset = 0
      paginator.value.page = 0
      paginator.value.limit = 10
      solicitudesStore.dispatch('getPaginate', {
        paginator: paginator.value,
        filters: filters1.value
      })
    })

    onMounted(async () => {
      await solicitudesStore.dispatch('getPaginate', {
        paginator: paginator.value,
        filters: filters1.value
      })
      await _UserService.getAll().then(({ data }) => {
        usuarios.value = data
      })
    })

    return {
      solicitudes,
      filters1,
      paginator,
      clearFilter1,
      onPage,
      remove,
      verPdf,
      usuarios,
      ver,
      reply,
      tipoSolicitudes
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
</style>
