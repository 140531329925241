import { createStore } from 'vuex'
import SolicitudesService from '../services/solicitudes.service'

const _SolicitudesService = new SolicitudesService()
const storeSolicitudes = {
  state: {
    _solicitudes: [],
    _solicitud: {},
    _solicitudTipo: [
      {
        id: 1,
        nombre: 'Tutelas'
      },
      {
        id: 2,
        nombre: 'Incidencias'
      },
      {
        id: 3,
        nombre: 'Fallos'
      },
      {
        id: 4,
        nombre: 'Respuestas'
      }
    ]
  },
  getters: {
    solicitudes: state => state._solicitudes,
    solicitud: state => state._solicitud,
    solicitudTipo: state => state._solicitudTipo
  },
  mutations: {
    setSolicitudes (state, payload) {
      state._solicitudes = payload
    },
    setSolicitud (state, payload) {
      state._solicitud = payload
    },
    setUpdateSolicitud (state, payload) {
      state._solicitud.nombre = payload.nombre
      state._solicitud.categoriaId = payload.categoriaId
      state._solicitud.code = payload.code
      state._solicitud.departamentoId = payload.departamentoId
      state._solicitud.municipioId = payload.municipioId
      state._solicitud.fechaVencimiento = payload.fechaVencimiento
    },
    setDefault (state) {
      state._solicitud = {}
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      _SolicitudesService.paginate(payload).then(({ data }) => {
        commit('setSolicitudes', data)
      })
    },
    createSolicitud ({ commit }, payload) {
      return _SolicitudesService.save(payload).then((response) => {
        return response
      })
    },
    findSolicitud ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _SolicitudesService.find(id).then(({ data }) => {
          commit('setSolicitud', data)
          resolve(data)
        })
      })
    },
    updateSolicitud ({ commit }, payload) {
      return _SolicitudesService.edit(payload, payload.id).then((response) => {
        return response
      })
    },
    removePdf ({ commit }, id) {
      return _SolicitudesService.removePdf(id).then((response) => {
        return response
      })
    },
    removeSolicitud ({ commit }, id) {
      return _SolicitudesService.remove(id).then((response) => {
        return response
      })
    },
    reply ({ commit }, payload) {
      return _SolicitudesService.reply(payload).then((response) => {
        return response
      })
    }
  }
}
const store = createStore(storeSolicitudes)
export default {
  namespaced: true,
  ...store
}
